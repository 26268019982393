import React from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom'; //Navigate

import { ThemeProvider } from "@mui/styles";
import { createTheme } from '@mui/material/styles';
import Notfound404 from './pages/NotFound404';
import Checkout from './pages/Checkout';
import Errormessage from './pages/ErrorPage';


const App: React.FC =()=> {


  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0, // Extra small
        sm: 600, // Small
        md: 1000, // Medium
        lg: 1200, // Large
        xl: 1536, // Extra large
        // Add custom breakpoints here (e.g., xxl: 1800)
      }
    },
  });


  return (
      <ThemeProvider theme={theme}>
        <div className="App">
      
          <BrowserRouter>
            <Routes>
              <Route path='/payment-checkout/:paymentid/:billindex' element={<Checkout />}/>
              <Route path='*' element={<Notfound404 />}/>
              <Route path='/purchase-failed' element={<Errormessage />}/>
            </Routes>
          </BrowserRouter>

        </div>
      </ThemeProvider>
  );
}

export default App;
