import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'

const Errormessage: React.FC= () => {
    const [ currentURL, setCurrentURL] = useState(window.location.pathname)

  useEffect(() => {
    document.title = 'Purchase failed';

  }, []);


  return (
    <div style={{padding: '0.5rem'}}>
        <h1 style={{marginBottom: '1rem'}}>OOPS!</h1>
        <p>Something went wrong and we were unable to process purchase. Please try again.</p>
        <a href='https://www.stargamingstore.shop/market-place' style={{display: 'block', margin: '0.7rem 0'}}>Return to store</a>
    </div>
  )
}

export default Errormessage