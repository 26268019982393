import React, { useEffect, useState } from 'react'
import CheckoutContainer from '../containers/CheckoutContainer';
import useStyles from '../styles/checkout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Audio } from 'react-loader-spinner'

interface Props {

}
const Checkout: React.FC<Props>= ({  }) => {
    const classes = useStyles()
    const [initalPaymentData, setInitalPaymentData] = useState({})
    const [cryptoRates, setCryptoRates] = useState({})
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Check out';
  }, []);
  
  const { paymentid, billindex } = useParams();
  const billIndex = Number(billindex) - 1
  useEffect(()=>{


    setLoading(true)
    const url = process.env.REACT_APP_GET_INITIAL_PAYMENT_DATA || ''
    axios.post(url, { paymentid: paymentid  })
    .then((response) => {
        setLoading(false)

        setInitalPaymentData(response.data.PaymentObject)
        setCryptoRates(response.data.CryptoRates[0])
    })
    .catch((error) => {
      setLoading(false)
      navigate('/purchase-failed')
    }
    )
  }, [])

  return (
    <>
      {
        loading
        ?
        <main style={{width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Audio
              visible={true}
              height="96"
              width="96"
              color="grey"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
        </main>
        :
        <div>
            <nav className={classes.nav}>
                {/* <Link to='https://www.stargamingstore.shop/market-place'  id='logo-holder' className={classes.logo}>
                    <img src="/imgs/logo.png" alt="Elit Credit Union Logo" style={{height: '85%'}}/>
                </Link>  */}

                <h6 className={classes.logoText}>Checkout<span style={{color: '#e0452f', fontSize: '2.6rem'}}>.</span></h6>
            </nav>
            <CheckoutContainer classes={classes} cryptoRates={cryptoRates} initalPaymentData={initalPaymentData} billIndex={billIndex}/>
          <div style={{textAlign: 'center', margin: '1rem 0', color: '#333', fontSize: '0.85rem'}}>
            © 2020 - 2024, Star Gaming Store. All rights reserved.
          </div>
        </div>
      }

    </>
  )
}

export default Checkout